const BtWorldCup = () => {
  return (
    <section className="bt-world-cup">
      <a href="https://itfbeachtennisworldcup-rio.com.br/">
        <img
          className="bt-world-cup__image"
          src="https://decathlonstore.vteximg.com.br/arquivos/bt-world-cup-image.png"
          alt="banner principal sandever"
        />
      </a>
      <div className="bt-world-cup__content">
        <h3 className="bt-world-cup__content--title">
          NOSSO PAPEL NO ITF BEACH TENNIS WORLD CUP - SANDEVER- BOLA OFICIAL DA
          COMPETIÇÃO MUNDIAL
        </h3>
        <p className="bt-world-cup__content--text">
          A organização da 2021 ITF Beach Tennis World Cup Pro and Junior
          anunciou a bola Sandever, marca própria de Beach Tennis da Decathlon,
          como a oficial da competição mundial, que será disputada pela primeira
          vez no Brasil, no Rio de Janeiro, de 4 a 10 de outubro.
        </p>
        <p className="bt-world-cup__content--text">
          A ITF Beach Tennis World Cup é a principal competição anual por
          equipes do ITF Beach Tennis World Tour e contará com as disputas das
          categorias Pro e Junior (18 anos).
        </p>
        <p className="bt-world-cup__content--text">
          A bola escolhida para o torneio é a{' '}
          <a href="https://www.decathlon.com.br/bola-de-tenis-de-praia-btb-900-s-roxo--28conjunto-de-2-29/p">
            Sandever BTB 900
          </a>
          , homologada pela Federação Internacional de Tênis (ITF), e que também
          já foi a bola oficial das edições de 2017, 2018 e 2019 da ITF Beach
          Tennis World Cup.
        </p>
        <p className="bt-world-cup__content--text">
          Disputam a ITF Beach Tennis World Cup atletas de 16 países na Pro e na
          Junior, contabilizando 192 atletas e 32 capitães. O Brasil é
          tricampeão da competição (2013, 2018 e 2019), estando atrás somente da
          Itália, que soma quatro títulos (2012, 2014, 2015 e 2017).
        </p>
      </div>
    </section>
  )
}

export default BtWorldCup
