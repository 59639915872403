const MainContentTop = () => {
  return (
    <>
      <div className="main-content left clearfix">
        <div className="main-content__image">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/michele-cappelletti-newest-lp.png"
            alt="Michele Cappelletti - Coleção Sandever"
            className="desk"
          />
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/michele-cappelletti-newest-lp-mobile.png"
            alt="Michele Cappelletti - Coleção Sandever"
            className="mob"
          />
          <a
            className="main-content__image__instagram"
            href="https://www.instagram.com/michelecappelletti92/"
          >
            <i className="icon-instagram" />
            @michelecappelletti92
          </a>
        </div>
        <div className="main-content__aside">
          <h2 className="main-content__aside__title">
            <div className="main-content__aside__title__inner">
              <span>
                Michele <br />
                Cappelletti
              </span>
              <small>4x Campeão Mundial</small>
            </div>
          </h2>
          <div className="main-content__aside__text first clearfix">
            <div className="main-content__aside__text__inner">
              <p>
                Italiano, nasceu em 29 de março de 1992. Iniciou no Beach <br />{' '}
                Tennis, aos 10 anos, em 2002. <br />
                Aos 15 anos, começou a treinar profissionalmente, dedicando-se{' '}
                <br />
                exclusivamente ao esporte.
              </p>
              <h5>Características: </h5>
              <ul>
                <li>- Destro, joga do lado esquerdo da quadra</li>
                <li>- Seu golpe preferido é o smash e o saque</li>
                <li>
                  - Sua Raquete é a{' '}
                  <a
                    href="https://www.decathlon.com.br/raquete-de-beach-tennis-btr-960-/p"
                    target="_blank"
                    rel="Sandever BTR 960 Blast noreferrer"
                  >
                    Sandever BTR 960 Blast
                  </a>
                </li>
              </ul>
              <h5>Principais conquistas:</h5>
              <ul>
                <li>
                  - Tetra campeão mundial em duplas masculinas (nos últimos 5
                  anos), feito inédito.
                </li>
                <li>
                  - Terceiro jogador com mais semanas como número 1 no mundo
                </li>
                <li>
                  - Tri Campeão do Torneio de Aruba, um dos mais prestigiados do
                  cenário mundial
                </li>
                <li>
                  - Campeão do Torneio de Kasan, em 2021, maior torneio em
                  premiação do mundo.
                </li>
                <li>- N.2 do Ranking Mundial ITF (Agosto/2021)</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="main-content__aside__video first">
          <iframe
            width="848"
            height="477"
            src="https://www.youtube.com/embed/yQ3bltPAwrc?controls=0"
            frameBorder="0"
            title="Youtube video player"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />
        </div>
      </div>
    </>
  )
}

export default MainContentTop
