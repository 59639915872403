import { Swiper, SwiperSlide } from 'swiper/react'
import { Lazy, Navigation, Pagination } from 'swiper'

const Blog = () => {
  return (
    <section className="section-blog">
      <div className="av-container">
        <div className="section-title">
          <h2 className="section-title__text">Dicas do Blog</h2>
        </div>
        <p className="texto-blog" />
        <div className="av-row posts-slider">
          <Swiper
            className="slick-slide-swiper-sandever"
            slidesPerView={1}
            pagination={{ clickable: true }}
            breakpoints={{
              1244: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 3,
              },
              1100: {
                slidesPerView: 3,
              },
              991: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1,
              },
              425: {
                slidesPerView: 1,
              },
              320: {
                slidesPerView: 1,
              },
            }}
            lazy={{
              loadPrevNext: true,
              loadPrevNextAmount: 1,
            }}
            modules={[Navigation, Lazy, Pagination]}
          >
            <SwiperSlide>
              <div className="post-1 av-col-md-8">
                <a href="https://souesportista.decathlon.com.br/os-golpes-do-beach-tennis/">
                  <img
                    src="https://decathlonstore.vteximg.com.br/arquivos/sandever-os-golpes-do-beach-tennis.jpg"
                    alt=""
                  />
                  <div className="texto-post">
                    <p className="titulo-post">OS GOLPES DO BEACH TENNIS</p>
                    <p className="conteudo-post">
                      {' '}
                      Conheça os principais golpes e dicas de como executá-los.
                    </p>
                  </div>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="post-2 av-col-md-8">
                <a href="https://souesportista.decathlon.com.br/equipamentos-para-iniciar-no-beach-tennis/">
                  <img
                    src="https://decathlonstore.vteximg.com.br/arquivos/sandever-equipamentos-para-iniciar.jpg"
                    alt=""
                  />
                  <div className="texto-post">
                    <p className="titulo-post">
                      EQUIPAMENTOS PARA INICIAR NO BEACH TENNIS
                    </p>
                    <p className="conteudo-post">
                      {' '}
                      Conheça os principais equipamentos e vestuários para
                      iniciar no esporte
                    </p>
                  </div>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="post-3 av-col-md-8">
                <a href="https://souesportista.decathlon.com.br/primeiros-passos-no-beach-tennis/">
                  <img
                    src="https://decathlonstore.vteximg.com.br/arquivos/sandever-primeiros-passos-beach-tennis.jpg"
                    alt=""
                  />
                  <div className="texto-post">
                    <p className="titulo-post">
                      SEUS PRIMEIROS PASSOS NO BEACH TENNIS!
                    </p>
                    <p className="conteudo-post">
                      Veja como iniciar no esporte e as principais dicas.
                    </p>
                  </div>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="post-2 av-col-md-8">
                <a href="https://souesportista.decathlon.com.br/as-regras-do-beach-tennis/">
                  <img
                    src="https://decathlonstore.vteximg.com.br/arquivos/sandever-as-regras-do-beach-tennis.jpg"
                    alt=""
                  />
                  <div className="texto-post">
                    <p className="titulo-post">
                      AS REGRAS DO BEACH TENNIS: O QUE VOCÊ PRECISA SABER
                    </p>
                    <p className="conteudo-post">
                      {' '}
                      Conheça mais sobre as regras e as principais curiosidades.
                    </p>
                  </div>
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="post-3 av-col-md-8">
                <a href="https://souesportista.decathlon.com.br/o-fenomeno-beach-tennis/">
                  <img
                    src="https://decathlonstore.vteximg.com.br/arquivos/sandever-saiba-mais-sobre-beach-tennis.jpg"
                    alt=""
                  />
                  <div className="texto-post">
                    <p className="titulo-post">
                      SAIBA MAIS SOBRE O FENÔMENO BEACH TENNIS!
                    </p>
                    <p className="conteudo-post">
                      {' '}
                      Conheça mais sobre um dos esportes que mais crescem no
                      Brasil.
                    </p>
                  </div>
                </a>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default Blog
