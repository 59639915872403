import Blog from './Blog'
import BtWorldCup from './BtWorldCup'
import InfoNews from './InfoNews'
import KnowMore from './KnowMore'
import MainBanner from './MainBanner'
import MainContentBottom from './MainContentBottom'
import MainContentMiddle from './MainContentMiddle'
import MainContentTop from './MainContentTop'
import MainTitle from './MainTitle'
import ProductHighlight from './ProductHighlight'
import ProductList from './ProductList'
import './styles.scss'

const SandeverBeachTenis = () => {
  return (
    <>
      <main className="main-sandever-beach-tenis" id="main">
        <div className="lp-sandever-bfbt">
          <MainBanner />
          <MainTitle />
          <section>
            <MainContentTop />
            <MainContentMiddle />
            <MainContentBottom />
          </section>
          <ProductList />
          <BtWorldCup />
          <ProductHighlight />
          <InfoNews />
          <KnowMore />
          <Blog />
        </div>
      </main>
    </>
  )
}

export default SandeverBeachTenis
