const MainContentBottom = () => {
  return (
    <div className="main-content left clearfix patty-mob">
      <div className="main-content__image">
        <img
          src="https://decathlonstore.vteximg.com.br/arquivos/patty-diaz-newest-lp.png"
          alt="Patty Diaz - Coleção Sandever"
          className="desk"
        />
        <img
          src="https://decathlonstore.vteximg.com.br/arquivos/patty-diaz-newest-lp-mobile.png"
          alt="Patty Diaz - Coleção Sandever"
          className="mob"
        />
        <a
          className="main-content__image__instagram patty-diaz"
          href="https://www.instagram.com/pattydiazbt/"
        >
          <i className="icon-instagram" />
          @pattydiazbt
        </a>
      </div>
      <div className="main-content__aside">
        <h2 className="main-content__aside__title">
          <div className="main-content__aside__title__inner">
            <span>
              Patty
              <br />
              Diaz
            </span>
          </div>
        </h2>
        <div className="main-content__aside__text third clearfix">
          <div className="main-content__aside__text__inner">
            <p>
              Venezuelana. Iniciou o Beach Tennis em 2012. Anteriormente, era{' '}
              <br /> tenista, mas, devido a lesões, precisou se afastar das
              quadras.
              <br /> Nesse período, conheceu o Beach Tennis e começou a disputar{' '}
              <br /> campeonatos no ano seguinte.
            </p>
            <h5>Características: </h5>
            <p>
              {' '}
              Destra, prefere jogar do lado direito da quadra, onde se sente
              melhor para construir as jogadas. É muito veloz e considerada uma
              das melhores defensoras do circuito mundial.{' '}
            </p>
            <h5>Principais conquistas:</h5>
            <ul>
              <li>- Melhor Ranking ITF : 1ª do mundo (2016)</li>
              <li>- Atual Ranking ITF: 6º do mundo (agosto/2021)</li>
              <li>- Já ganhou mais de 50 etapas do circuito mundial</li>
              <li>
                - Já foi considerada a MVP (melhor jogadora) do torneio de
                Aruba, um dos torneios mais prestigiados do mundo.
              </li>
              <li>- 2010 a 2017: Se manteve no top 10 durante 7 anos</li>
              <li>
                - 2015: Fez história, sendo a primeira não italiana a chegar em
                uma final de Campeonato Mundial.
              </li>
              <li>
                - Começou sua carreira profissional em 2013, treinando com os
                melhores do mundo na Itália
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="main-content__aside__video third">
        <iframe
          width="848"
          height="477"
          src="https://www.youtube.com/embed/jOj0EYMkubs?controls=0"
          frameBorder="0"
          title="youtube video"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
      </div>
    </div>
  )
}

export default MainContentBottom
