const ProductHighlight = () => {
  return (
    <section className="product-highlight container">
      <h2 className="section-title">NOSSA BOLA</h2>
      <div className="product-highlight__inner">
        <img
          src="https://decathlonstore.vteximg.com.br/arquivos/bola-sandever-beach-tennis.png"
          alt="Bola de Tênis de Praia BTB 900 S"
        />
        <div className="product-highlight__aside">
          <div className="product-highlight__aside__text">
            <h3>BOLA TÊNIS DE PRAIA BTB 900 S</h3>
            <p>
              Desenvolvido para os atletas amadores e profissionais de beach
              tennis. Bola Oficial dos últimos três campeonatos mundiais ITF.
            </p>
            <p>Bola Homolagada ITF Stage 2</p>
          </div>
          <a
            className="button button--medium"
            href="https://www.decathlon.com.br/bola-de-tenis-de-praia-btb-900-s-roxo--28conjunto-de-2-29/p"
          >
            Comprar Bola
          </a>
        </div>
      </div>
    </section>
  )
}

export default ProductHighlight
