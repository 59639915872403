const InfoNews = () => {
  return (
    <section className="info-news">
      <section className="info-news">
        <h2 className="info-news__title">SAIU NA IMPRENSA</h2>
        <div className="info-news__content1">
          <img
            className="info-news__content1--image"
            src="https://decathlonstore.vteximg.com.br/arquivos/sandever-own-brand-image.png?v=637678381557700000"
            alt="Marca Própria da Decathlon - Sandever"
          />
          <h3 className="info-news__content1--title">
            Sandever, marca própria da Decathlon, será bola oficial do Mundial
            de Beach Tennis
          </h3>
          <p className="info-news__content1--text">
            A organização da 2021 ITF Beach Tennis World Cup Pro and Junior
            anunciou, nesta quinta-feira (22), a Sandever, marca própria de
            beach tennis da Decathlon, como a bola oficial da competição
            mundial, que será disputada pela primeira vez no Brasil, mais
            especificamente no Rio de Janeiro, de 4 a 10 de outubro.
            <br />
            <span>Fonte: Maquina do esporte.</span>
          </p>
          <a
            href="https://www.maquinadoesporte.com.br/outros/sandever-marca-propria-da-decathlon-sera-bola-oficial-do-mundial-de-beach-tennis"
            className="info-news__content1--readmore"
          >
            Ler mais
          </a>
        </div>
        <div className="info-news__content2">
          <img
            className="info-news__content2--image"
            src="https://decathlonstore.vteximg.com.br/arquivos/sandever-oficial-brand-image.png"
            alt="Marca oficial do mundial - Sandever"
          />
          <h3 className="info-news__content2--title">
            Sandever será bola oficial do Mundial de Beach Tennis
          </h3>
          <p className="info-news__content2--text">
            A bola escolhida para o torneio é a Sandever BTB 900, homologada
            pela Federação Internacional de Tênis (ITF), e que também já foi a
            bola oficial das edições de 2017, 2018 e 2019 da ITF Beach Tennis
            World Cup. O Mundial é a principal competição anual por equipes do
            ITF Beach Tennis World Tour e contará com as disputas das categorias
            Pro e Junior (18 anos).
            <br />
            <span>Fonte: Mkt Esportivo</span>
          </p>
          <a
            href="https://www.mktesportivo.com/2021/07/sandever-sera-bola-oficial-do-mundial-de-beach-tennis/"
            className="info-news__content2--readmore"
          >
            Ler mais
          </a>
        </div>
      </section>
    </section>
  )
}

export default InfoNews
