const MainTitle = () => {
  return (
    <h1 className="main-title">
      <img
        src="https://decathlonstore.vteximg.com.br/arquivos/logo-sandever-born-for-beach-tennis.png"
        alt="Logo Sandever -  Born for Beach Tennis"
      />
      <div className="main-title__small">
        <p className="text-title">
          <b>Sandever</b> é a marca de Beach Tennis exclusiva da Decathlon.
          Acreditamos que todos nasceram para o Beach Tennis e desenvolvemos
          produtos com tecnicidade para diferentes níveis de esportistas.
          Sandever conta com{' '}
          <a href="https://www.decathlon.com.br/beach-tennis/equipamentos/raquetes#1">
            raquetes
          </a>
          ,{' '}
          <a href="https://www.decathlon.com.br/frescobol/equipamentos/bolas#1">
            bolas
          </a>
          ,{' '}
          <a href="https://www.decathlon.com.br/beach-tennis/acessorios/munhequeiras#1">
            munhequeiras
          </a>
          ,{' '}
          <a href="https://www.decathlon.com.br/beach-tennis/equipamentos/redes-e-kits#1">
            kits de marcação para quadra
          </a>
          ,{' '}
          <a href="https://www.decathlon.com.br/beach-tennis/equipamentos/redes-e-kits#1">
            rede de beach tennis
          </a>
          ,{' '}
          <a href="https://www.decathlon.com.br/beach-tennis/equipamentos/raqueteiras#1">
            raqueteira
          </a>{' '}
          e tudo o que é necessário para a prática deste esporte que é a cara do
          Brasil.{' '}
        </p>

        <p className="text-title">
          A marca possui ainda uma coleção de raquetes desenvolvidas em parceria
          com os maiores campeões mundiais do esporte atualmente:{' '}
          <b>Michelle Cappelletti</b>, atleta italiano tetracampeão mundial em
          duplas masculinas (nos últimos 5 anos), feito inédito, terceiro
          jogador com mais semanas como número 1 no mundo, tricampeão do Torneio
          de Aruba, um dos mais prestigiados do cenário mundial, N.2 do Ranking
          Mundial ITF (Março/2020), assina a
          <b>
            <a href="https://www.decathlon.com.br/raquete-de-beach-tennis-btr-960-/p">
              Sandever BTR 960 BLAST
            </a>{' '}
          </b>
          .
        </p>

        <p className="text-title">
          <b>Rafaella Miller</b>, brasileira, 2 vezes campeã mundial em duplas
          femininas, 2 vezes campeã mundial por equipes, campeã em duplas mistas
          dos Jogos Mundiais de Esportes de Areia e Melhor Ranking ITF: 1ª do
          mundo (março/2020) joga com{' '}
          <b>
            <a href="https://www.decathlon.com.br/raquete-de-beach-tennis-btr-900-control/p">
              Sandever BTR 900 Control
            </a>{' '}
          </b>
          .
        </p>
        <p className="text-title">
          <b>Patty Diaz</b>, venezuelana, joga do lado direito da quadra, onde
          se sente melhor para construir as jogadas. É muito veloz e considerada
          uma das melhores defensoras do circuito mundial. Já ganhou mais de 50
          etapas do circuito mundial e já foi considerada a MVP (melhor
          jogadora) do torneio de Aruba.
        </p>
      </div>
    </h1>
  )
}

export default MainTitle
