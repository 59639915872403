const KnowMore = () => {
  return (
    <section className="know-more">
      <h2 className="know-more__title">CONHEÇA MAIS SOBRE</h2>
      <div className="know-more__content">
        <a href="https://www.decathlon.com.br/beach-tennis?event_category=menu_principal&event_action=click&event_label=beach_tennis#1">
          <img
            className="know-more__content--image"
            src="https://decathlonstore.vteximg.com.br/arquivos/sandever-equipamentos.png"
            alt="Equipamentos"
          />
        </a>
        <a href="https://www.decathlon.com.br/beach-tennis/equipamentos/grips-e-overgrips#1">
          <img
            className="know-more__content--image"
            src="https://decathlonstore.vteximg.com.br/arquivos/sandever-acessorios.png"
            alt="Acessórios"
          />
        </a>
        <a href="https://www.decathlon.com.br/beach-tennis/equipamentos/redes-e-kits#1">
          <img
            className="know-more__content--image"
            src="https://decathlonstore.vteximg.com.br/arquivos/sandever-kits.png"
            alt="Kits"
          />
        </a>
        <a href="https://www.decathlon.com.br/beach-tennis/equipamentos/raquetes#1">
          <img
            className="know-more__content--image"
            src="https://decathlonstore.vteximg.com.br/arquivos/sandever-raquetes.png"
            alt="Raquetes"
          />
        </a>
      </div>
    </section>
  )
}

export default KnowMore
