import { Swiper, SwiperSlide } from 'swiper/react'
import { Lazy, Navigation } from 'swiper'

const ProductList = () => {
  return (
    <section className="product-list container">
      <h2 className="section-title">As Raquetes</h2>
      <div className="product-list__inner">
        <Swiper
          className="slick-slide-swiper-sandever"
          slidesPerView={1}
          navigation
          // pagination={{ clickable: true }}
          breakpoints={{
            1244: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 3,
            },
            1100: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 1,
            },
            425: {
              slidesPerView: 1,
            },
            320: {
              slidesPerView: 1,
            },
          }}
          lazy={{
            loadPrevNext: true,
            loadPrevNextAmount: 1,
          }}
          modules={[Navigation, Lazy]}
        >
          <SwiperSlide>
            <div className="product-list__item">
              <img
                src="https://decathlonstore.vteximg.com.br/arquivos/raquete-sandever-BTR%20960%20BLAST.png"
                alt="Raquete Sandever BTR 960 BLAST"
              />
              <ul>
                <li>
                  <h4>BTR 960 BLAST</h4>
                </li>
                <li>CARBONO 3K</li>
                <li>50 cm</li>
                <li>340 /- 5 G</li>
                <li>21 MM</li>
                <li>EQUILÍBRIO: 25 CM</li>
              </ul>
              <a
                className="button button--medium"
                href="https://www.decathlon.com.br/raquete-de-beach-tennis-btr-960-/p"
              >
                Comprar Raquete
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="product-list__item">
              <img
                src="https://decathlonstore.vteximg.com.br/arquivos/raquete-sandever-BTR%20900%20CONTROL.png"
                alt="Raquete Sandever BTR 900 CONTROL"
              />
              <ul>
                <li>
                  <h4>BTR 900 CONTROL</h4>
                </li>
                <li>Carbono 12K</li>
                <li>50 cm</li>
                <li>335 /- 5 G</li>
                <li>22 MM</li>
                <li>EQUILÍBRIO: 25 CM</li>
              </ul>
              <a
                className="button button--medium"
                href="https://www.decathlon.com.br/raquete-de-beach-tennis-btr-900-control/p"
              >
                Comprar Raquete
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="product-list__item">
              <img
                src="https://decathlonstore.vteximg.com.br/arquivos/raquete-sandever-BTR%20990%20SPEED.png"
                alt="Raquete Sandever BTR 990 SPEED"
              />
              <ul>
                <li>
                  <h4>BTR 990 SPEED</h4>
                </li>
                <li>KEVLAR</li>
                <li>50 CM</li>
                <li>345 /- 5 G</li>
                <li>20 MM</li>
                <li>Equilíbrio: 26 CM</li>
              </ul>
              <a
                className=" button button--medium"
                href="https://www.decathlon.com.br/raquete-de-beach-tennis-btr-990-speed/p"
              >
                Comprar Raquete
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="product-list__item">
              <img
                src="https://decathlonstore.vteximg.com.br/arquivos/raquete-sandever-BTR%20990%20PRECISION.png"
                alt="Raquete Sandever BTR 990 PRECISION"
              />
              <ul>
                <li>
                  <h4>BTR 990 PRECISION</h4>
                </li>
                <li>CARBONO 15K</li>
                <li>50 cm</li>
                <li>350 /- 5 G</li>
                <li>21 MM</li>
                <li>Equilíbrio: 25 CM</li>
              </ul>
              <a
                className="button button--medium"
                href="https://www.decathlon.com.br/raquete-de-beach-tennis-btr-990-2/p"
              >
                Comprar Raquete
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  )
}

export default ProductList
