const MainContentMiddle = () => {
  return (
    <div className="main-content right clearfix">
      <div className="main-content__image">
        <img
          className="d-desk"
          src="https://decathlonstore.vteximg.com.br/arquivos/rafa-miiller-newest-lp.png"
          alt="Rafaela Miller - Coleção Sandever"
        />
        <img
          className="d-mob"
          src="https://decathlonstore.vteximg.com.br/arquivos/rafa-miiller-newest-lp-mobile.png"
          alt="Rafaela Miller - Coleção Sandever"
        />
        <a
          className="main-content__image__instagram rafa-insta"
          href="https://www.instagram.com/rafamiiller/"
        >
          <i className="icon-instagram" />
          @rafamiiller
        </a>
      </div>
      <div className="main-content__aside">
        <h2 className="main-content__aside__title">
          <div className="main-content__aside__title__inner">
            <span>
              Rafaella <br />
              Miller
            </span>
            <small>2x Campeã Mundial</small>
          </div>
        </h2>
        <div className="main-content__aside__text second clearfix">
          <div className="main-content__aside__text__inner">
            <p>
              Brasileira, nascida em 14 de maio de 1993. Iniciou sua trajetória
              no Beach Tennis em 2014, aos 21 anos, após anos dedicados ao
              tênis.
            </p>
            <h5>Características: </h5>
            <ul>
              <li>- Destra, costuma jogar do lado esquerdo</li>
              <li>- Seu golpe preferido é o smash</li>
              <li>
                - Sua Raquete é a{' '}
                <a
                  href="https://www.decathlon.com.br/raquete-de-beach-tennis-btr-900-control/p"
                  rel="Sandever BTR 900 Control"
                >
                  Sandever BTR 900 Control
                </a>
              </li>
            </ul>
            <h5>Principais conquistas:</h5>
            <ul>
              <li>- 2 vezes campeã mundial em duplas femininas</li>
              <li>- 2 vezes campeã mundial com a seleção brasileira</li>
              <li>
                - Campeã em duplas mistas dos Jogos Mundiais de Esportes de
                Areia.
              </li>
              <li>- Melhor Ranking ITF : 1ª do mundo (julho/2021)</li>
              <li>- Atual Ranking ITF: 2º do mundo (agosto/2021)</li>
            </ul>
            <div className="main-content__aside__video second">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/7G_ZKaJiJ-4?controls=0"
                frameBorder="0"
                title="Youtube video player"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainContentMiddle
