const MainBanner = () => {
  return (
    <section className="banner-breadcrumb">
      <div className="info-frete">
        Frete grátis para compras acima de R$199 válido para todo o Brasil.
      </div>
      <div className="breadcrumb">
        <a href="https://www.decathlon.com.br" className="">
          <i className="icon-home" />
          Início
        </a>
        BEACH TENNIS
      </div>
      <img
        className="banner-principal"
        src="https://decathlonstore.vteximg.com.br/arquivos/banner-principal-sandever.png"
        alt="banner principal sandever"
      />
    </section>
  )
}

export default MainBanner
